import React, { useContext, useEffect, useState } from 'react';
import { Avatar, Button, Descriptions, Divider, PageHeader, Row, Space, Spin } from 'antd';
import { useParams, useHistory } from 'react-router';
import { TeamOutlined, EditOutlined, RollbackOutlined } from '@ant-design/icons';

import { StoreContext } from '../../store';
import { RoleModel } from '../../../api';
import { roleApi } from '../../apis';
import { AlertTypes } from '../../constants';

const RoleCard: React.FC<{}> = () => {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const { SetAlert } = useContext(StoreContext).actions;
  const [loading, setLoading] = useState(false);
  const [role, setRole] = useState({} as RoleModel);

  const loadDataAsync = async () => {
    setLoading(true);
    try {
      const response = await roleApi.getRole(id);
      setRole(response.data);
    } catch (e) {
      SetAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при загрузке карточки роли',
        description: e.toString(),
      });
    }
    setLoading(false);
  };
  const loadData = () => {
    loadDataAsync();
  };
  useEffect(loadData, []);

  const onBackBtnClick = () => {
    history.push('/roles');
  };

  const onEditBtnClick = () => {
    history.push(`/roles/${role.id}/edit`);
  };

  if (loading)
    return (
      <Row justify="center">
        <Spin />
      </Row>
    );
  return (
    <div>
      <PageHeader
        extra={[
          <Button key="edit" icon={<EditOutlined />} type="primary" onClick={onEditBtnClick}>
            Редактировать
          </Button>,
          <Button key="back" icon={<RollbackOutlined />} onClick={onBackBtnClick}>
            Список
          </Button>,
        ]}
        title={
          <Space>
            <Avatar icon={<TeamOutlined />} size={64} />
            <Space direction="vertical">
              <span>{role.name}</span>
              <span>Роль</span>
            </Space>
          </Space>
        }
      />
      <Divider />
      <Descriptions bordered column={1}>
        <Descriptions.Item label="ИД">{role.id}</Descriptions.Item>
        <Descriptions.Item label="Наименование">{role.name}</Descriptions.Item>
        <Descriptions.Item label="Описание">{role.description}</Descriptions.Item>
      </Descriptions>
    </div>
  );
};

export default RoleCard;
