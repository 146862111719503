import { IActionHandlers, IStore } from '../types';

import * as alert from './alert';

const actions = {
  ...alert,
};

type ActionArgumentsType<T extends (...args: any[]) => any> = T extends (store: IStore, ...args: infer R) => any
  ? R
  : any[];

type MapActionCreators<T extends IActionHandlers> = {
  [P in keyof T]: (...rest: ActionArgumentsType<T[P]>) => ReturnType<T[P]>;
};

export type Actions = MapActionCreators<typeof actions>;

export default actions;
