import React from 'react';
import { Menu, Dropdown, Button } from 'antd';
import { UserOutlined, DownOutlined, LogoutOutlined } from '@ant-design/icons';

import { getProfile, logoutUser } from '../utils/oidcUtil';

const handleAction = async (e: { key: string }) => {
  switch (e.key) {
    case 'logout':
      await logoutUser({});
      break;
  }
};

const UserInfo: React.FC<{}> = () => {
  const profile = getProfile();

  const userMenu = (
    <Menu onClick={handleAction}>
      <Menu.Item key="logout" icon={<LogoutOutlined />}>
        Выйти
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown overlay={userMenu}>
      <Button>
        <UserOutlined /> {profile.userName} <DownOutlined />
      </Button>
    </Dropdown>
  );
};

export default UserInfo;
