import React from 'react';
import { Table } from 'antd';

import { TenantExternalInfoModel } from '../../../api';

export interface IExternalInfoListViewProps {
  externals: TenantExternalInfoModel[];
}

const ExternalInfoListView: React.FC<IExternalInfoListViewProps> = ({ externals }) => {
  const columns = [
    {
      title: 'ИД внешнего поставщика',
      dataIndex: 'providerId',
      key: 'providerId',
    },
    {
      title: 'ИД тенанта в поставщике',
      dataIndex: 'tenantId',
      key: 'tenantId',
    },
  ];

  return <Table columns={columns} dataSource={externals} pagination={false} />;
};

export default ExternalInfoListView;
