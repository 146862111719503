import React, { useContext, useState, useEffect } from 'react';
import { Button, Form, Input, PageHeader, Space } from 'antd';
import { useParams } from 'react-router';
import { useHistory } from 'react-router';
import { RollbackOutlined, SaveOutlined } from '@ant-design/icons';
import { StatusCodes } from 'http-status-codes';

import { StoreContext } from '../../store';
import { RoleModel, RoleUpdatingRequest } from '../../../api';
import { roleApi } from '../../apis';
import { AlertTypes, IDENTITY_ERROR_PREFIX } from '../../constants';

const RoleEdit: React.FC<{}> = () => {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const [form] = Form.useForm();
  const { SetAlert } = useContext(StoreContext).actions;
  const [loading, setLoading] = useState(false);
  const [role, setRole] = useState({} as RoleModel);

  const loadDataAsync = async () => {
    setLoading(true);
    try {
      const response = await roleApi.getRole(id);
      setRole(response.data);
    } catch (e) {
      SetAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при загрузке данных роли',
        description: e.toString(),
      });
    }
    setLoading(false);
  };
  const loadData = () => {
    loadDataAsync();
  };
  useEffect(loadData, []);

  const loadDataToForm = () => {
    form.setFieldsValue({
      name: role.name,
      description: role.description,
    });
  };
  useEffect(loadDataToForm, [role]);

  const onFinish = async () => {
    const newRole: RoleUpdatingRequest = {
      name: form.getFieldValue('name'),
      description: form.getFieldValue('description'),
    };

    setLoading(true);
    try {
      await roleApi.updateRole(id, newRole);
      SetAlert({
        type: AlertTypes.SUCCESS,
        message: 'Роль изменена',
        description: newRole.name,
      });
      history.push(`/roles/${id}`);
    } catch (e) {
      if (e.response.status === StatusCodes.UNPROCESSABLE_ENTITY) {
        const problem = e.response.data;
        const errors = Object.keys(problem).filter((k) => k.startsWith(IDENTITY_ERROR_PREFIX));

        if (errors.length) {
          form.setFields([
            {
              name: 'name',
              value: newRole.name,
              errors: errors.map((k) => problem[k]),
            },
          ]);
          setLoading(false);
          return;
        }
      }

      SetAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при изменении роли',
        description: e.toString(),
      });
      setLoading(false);
    }
  };

  const onBackBtnClick = () => {
    history.push('/roles');
  };

  return (
    <div>
      <PageHeader title={`Редактирование роли ${role.name}`} />
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Form.Item label="Наименование" name="name" rules={[{ required: true, message: 'Введите наименование' }]}>
          <Input placeholder="Наименование" />
        </Form.Item>
        <Form.Item label="Описание" name="description">
          <Input placeholder="Описание" />
        </Form.Item>
        <Form.Item>
          <Space>
            <Button disabled={loading} htmlType="submit" icon={<SaveOutlined />} type="primary">
              Сохранить
            </Button>
            <Button icon={<RollbackOutlined />} onClick={onBackBtnClick}>
              Отмена
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </div>
  );
};

export default RoleEdit;
