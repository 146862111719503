import React, { useContext, useEffect, useState } from 'react';
import { Avatar, Button, Descriptions, Divider, PageHeader, Row, Space, Spin } from 'antd';
import { useParams, useHistory } from 'react-router';
import { UserOutlined, EditOutlined, RollbackOutlined } from '@ant-design/icons';

import { StoreContext } from '../../store';
import { UserModel } from '../../../api';
import { userApi } from '../../apis';
import { AlertTypes } from '../../constants';
import PasswordChanging from '../../components/users/PasswordChanging';
import LoginInfoListView from '../../components/users/LoginInfoListView';

const UserCard: React.FC<{}> = () => {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const { SetAlert } = useContext(StoreContext).actions;
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState({} as UserModel);

  const loadDataAsync = async () => {
    setLoading(true);
    try {
      const response = await userApi.getUser(id);
      setUser(response.data);
    } catch (e) {
      SetAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при загрузке карточки пользователя',
        description: e.toString(),
      });
    }
    setLoading(false);
  };
  const loadData = () => {
    loadDataAsync();
  };
  useEffect(loadData, []);

  const onBackBtnClick = () => {
    history.push('/users');
  };

  const onEditBtnClick = () => {
    history.push(`/users/${user.id}/edit`);
  };

  if (loading)
    return (
      <Row justify="center">
        <Spin />
      </Row>
    );
  return (
    <div>
      <PageHeader
        extra={[
          <Button key="edit" icon={<EditOutlined />} type="primary" onClick={onEditBtnClick}>
            Редактировать
          </Button>,
          <Button key="back" icon={<RollbackOutlined />} onClick={onBackBtnClick}>
            Список
          </Button>,
        ]}
        title={
          <Space>
            <Avatar icon={<UserOutlined />} size={64} />
            <Space direction="vertical">
              <span>{user.login}</span>
              <span>Пользователь</span>
            </Space>
          </Space>
        }
      />
      <Divider />
      <Descriptions bordered column={1}>
        <Descriptions.Item label="ИД">{user.id}</Descriptions.Item>
        <Descriptions.Item label="Логин">{user.login}</Descriptions.Item>
        <Descriptions.Item label="Пароль">
          <PasswordChanging user={user} />
        </Descriptions.Item>
        <Descriptions.Item label="Email">{user.email}</Descriptions.Item>
        <Descriptions.Item label="Телефон">{user.phoneNumber}</Descriptions.Item>
        <Descriptions.Item label="Полное ФИО">{user.fullName}</Descriptions.Item>
        <Descriptions.Item label="Краткое ФИО">{user.shortName}</Descriptions.Item>
        <Descriptions.Item label="Фамилия">{user.familyName}</Descriptions.Item>
        <Descriptions.Item label="Имя">{user.givenName}</Descriptions.Item>
        <Descriptions.Item label="Отчество">{user.middleName}</Descriptions.Item>
        <Descriptions.Item label="Тенанты">{user.tenantNames}</Descriptions.Item>
        <Descriptions.Item label="Роли">{user.roleNames}</Descriptions.Item>
      </Descriptions>
      <Divider />
      <LoginInfoListView logins={user.logins || []} />
    </div>
  );
};

export default UserCard;
