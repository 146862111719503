/* tslint:disable */
/* eslint-disable */
/**
 * Администрирование ELMA Bot
 * Управление пользователями, тенантами и т.д.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * Модель информации о приложении.
 * @export
 * @interface AboutModel
 */
export interface AboutModel {
    /**
     * Наименование.
     * @type {string}
     * @memberof AboutModel
     */
    name?: string | null;
    /**
     * Описание.
     * @type {string}
     * @memberof AboutModel
     */
    description?: string | null;
    /**
     * Версия.
     * @type {string}
     * @memberof AboutModel
     */
    version?: string | null;
}
/**
 * Модель информации о работоспособности.
 * @export
 * @interface HealthModel
 */
export interface HealthModel {
    /**
     * Статус работоспособности.
     * @type {string}
     * @memberof HealthModel
     */
    status?: string | null;
}
/**
 * 
 * @export
 * @interface PasswordChangingRequest
 */
export interface PasswordChangingRequest {
    /**
     * 
     * @type {string}
     * @memberof PasswordChangingRequest
     */
    currentPassword: string;
    /**
     * 
     * @type {string}
     * @memberof PasswordChangingRequest
     */
    newPassword: string;
}
/**
 * 
 * @export
 * @interface ProblemDetails
 */
export interface ProblemDetails {
    [key: string]: any | any;

    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    type?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    title?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProblemDetails
     */
    status?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    detail?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    instance?: string | null;
}
/**
 * 
 * @export
 * @interface RoleCreationRequest
 */
export interface RoleCreationRequest {
    /**
     * 
     * @type {string}
     * @memberof RoleCreationRequest
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof RoleCreationRequest
     */
    description?: string | null;
}
/**
 * 
 * @export
 * @interface RoleCreationResponse
 */
export interface RoleCreationResponse {
    /**
     * 
     * @type {string}
     * @memberof RoleCreationResponse
     */
    id?: string | null;
}
/**
 * 
 * @export
 * @interface RoleModel
 */
export interface RoleModel {
    /**
     * 
     * @type {string}
     * @memberof RoleModel
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RoleModel
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RoleModel
     */
    description?: string | null;
}
/**
 * 
 * @export
 * @interface RoleModelPaginationResponse
 */
export interface RoleModelPaginationResponse {
    /**
     * 
     * @type {Array<RoleModel>}
     * @memberof RoleModelPaginationResponse
     */
    items?: Array<RoleModel> | null;
    /**
     * 
     * @type {boolean}
     * @memberof RoleModelPaginationResponse
     */
    hasMore?: boolean;
    /**
     * 
     * @type {number}
     * @memberof RoleModelPaginationResponse
     */
    pageIndex?: number;
    /**
     * 
     * @type {number}
     * @memberof RoleModelPaginationResponse
     */
    pageSize?: number;
    /**
     * 
     * @type {number}
     * @memberof RoleModelPaginationResponse
     */
    totalItemCount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RoleModelPaginationResponse
     */
    totalPageCount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RoleModelPaginationResponse
     */
    fromItemInclusive?: number;
    /**
     * 
     * @type {number}
     * @memberof RoleModelPaginationResponse
     */
    toItemExclusive?: number;
}
/**
 * 
 * @export
 * @interface RoleUpdatingRequest
 */
export interface RoleUpdatingRequest {
    /**
     * 
     * @type {string}
     * @memberof RoleUpdatingRequest
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof RoleUpdatingRequest
     */
    description?: string | null;
}
/**
 * 
 * @export
 * @interface TenantCreationRequest
 */
export interface TenantCreationRequest {
    /**
     * 
     * @type {string}
     * @memberof TenantCreationRequest
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof TenantCreationRequest
     */
    description?: string | null;
    /**
     * 
     * @type {Array<TenantExternalInfoModel>}
     * @memberof TenantCreationRequest
     */
    externals?: Array<TenantExternalInfoModel> | null;
}
/**
 * 
 * @export
 * @interface TenantCreationResponse
 */
export interface TenantCreationResponse {
    /**
     * 
     * @type {string}
     * @memberof TenantCreationResponse
     */
    id?: string | null;
}
/**
 * 
 * @export
 * @interface TenantExternalInfoModel
 */
export interface TenantExternalInfoModel {
    /**
     * 
     * @type {string}
     * @memberof TenantExternalInfoModel
     */
    providerId: string;
    /**
     * 
     * @type {string}
     * @memberof TenantExternalInfoModel
     */
    tenantId: string;
}
/**
 * 
 * @export
 * @interface TenantModel
 */
export interface TenantModel {
    /**
     * 
     * @type {string}
     * @memberof TenantModel
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TenantModel
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TenantModel
     */
    description?: string | null;
    /**
     * 
     * @type {Array<TenantExternalInfoModel>}
     * @memberof TenantModel
     */
    externals?: Array<TenantExternalInfoModel> | null;
}
/**
 * 
 * @export
 * @interface TenantModelPaginationResponse
 */
export interface TenantModelPaginationResponse {
    /**
     * 
     * @type {Array<TenantModel>}
     * @memberof TenantModelPaginationResponse
     */
    items?: Array<TenantModel> | null;
    /**
     * 
     * @type {boolean}
     * @memberof TenantModelPaginationResponse
     */
    hasMore?: boolean;
    /**
     * 
     * @type {number}
     * @memberof TenantModelPaginationResponse
     */
    pageIndex?: number;
    /**
     * 
     * @type {number}
     * @memberof TenantModelPaginationResponse
     */
    pageSize?: number;
    /**
     * 
     * @type {number}
     * @memberof TenantModelPaginationResponse
     */
    totalItemCount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TenantModelPaginationResponse
     */
    totalPageCount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TenantModelPaginationResponse
     */
    fromItemInclusive?: number;
    /**
     * 
     * @type {number}
     * @memberof TenantModelPaginationResponse
     */
    toItemExclusive?: number;
}
/**
 * 
 * @export
 * @interface TenantUpdatingRequest
 */
export interface TenantUpdatingRequest {
    /**
     * 
     * @type {string}
     * @memberof TenantUpdatingRequest
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof TenantUpdatingRequest
     */
    description?: string | null;
    /**
     * 
     * @type {Array<TenantExternalInfoModel>}
     * @memberof TenantUpdatingRequest
     */
    externals?: Array<TenantExternalInfoModel> | null;
}
/**
 * 
 * @export
 * @interface UserCreationRequest
 */
export interface UserCreationRequest {
    /**
     * 
     * @type {string}
     * @memberof UserCreationRequest
     */
    login: string;
    /**
     * 
     * @type {string}
     * @memberof UserCreationRequest
     */
    password: string;
    /**
     * 
     * @type {string}
     * @memberof UserCreationRequest
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof UserCreationRequest
     */
    phoneNumber: string;
    /**
     * 
     * @type {string}
     * @memberof UserCreationRequest
     */
    fullName: string;
    /**
     * 
     * @type {string}
     * @memberof UserCreationRequest
     */
    shortName: string;
    /**
     * 
     * @type {string}
     * @memberof UserCreationRequest
     */
    familyName: string;
    /**
     * 
     * @type {string}
     * @memberof UserCreationRequest
     */
    givenName: string;
    /**
     * 
     * @type {string}
     * @memberof UserCreationRequest
     */
    middleName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserCreationRequest
     */
    tenantNames?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserCreationRequest
     */
    roleNames?: string | null;
    /**
     * 
     * @type {Array<UserLoginInfoModel>}
     * @memberof UserCreationRequest
     */
    logins?: Array<UserLoginInfoModel> | null;
}
/**
 * 
 * @export
 * @interface UserCreationResponse
 */
export interface UserCreationResponse {
    /**
     * 
     * @type {string}
     * @memberof UserCreationResponse
     */
    id?: string | null;
}
/**
 * 
 * @export
 * @interface UserLoginInfoModel
 */
export interface UserLoginInfoModel {
    /**
     * 
     * @type {string}
     * @memberof UserLoginInfoModel
     */
    loginProvider: string;
    /**
     * 
     * @type {string}
     * @memberof UserLoginInfoModel
     */
    providerKey: string;
    /**
     * 
     * @type {string}
     * @memberof UserLoginInfoModel
     */
    providerDisplayName?: string | null;
}
/**
 * 
 * @export
 * @interface UserModel
 */
export interface UserModel {
    /**
     * 
     * @type {string}
     * @memberof UserModel
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserModel
     */
    login?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserModel
     */
    password?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserModel
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserModel
     */
    phoneNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserModel
     */
    fullName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserModel
     */
    shortName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserModel
     */
    familyName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserModel
     */
    givenName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserModel
     */
    middleName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserModel
     */
    tenantNames?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserModel
     */
    roleNames?: string | null;
    /**
     * 
     * @type {Array<UserLoginInfoModel>}
     * @memberof UserModel
     */
    logins?: Array<UserLoginInfoModel> | null;
}
/**
 * 
 * @export
 * @interface UserModelPaginationResponse
 */
export interface UserModelPaginationResponse {
    /**
     * 
     * @type {Array<UserModel>}
     * @memberof UserModelPaginationResponse
     */
    items?: Array<UserModel> | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserModelPaginationResponse
     */
    hasMore?: boolean;
    /**
     * 
     * @type {number}
     * @memberof UserModelPaginationResponse
     */
    pageIndex?: number;
    /**
     * 
     * @type {number}
     * @memberof UserModelPaginationResponse
     */
    pageSize?: number;
    /**
     * 
     * @type {number}
     * @memberof UserModelPaginationResponse
     */
    totalItemCount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UserModelPaginationResponse
     */
    totalPageCount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UserModelPaginationResponse
     */
    fromItemInclusive?: number;
    /**
     * 
     * @type {number}
     * @memberof UserModelPaginationResponse
     */
    toItemExclusive?: number;
}
/**
 * 
 * @export
 * @interface UserUpdatingRequest
 */
export interface UserUpdatingRequest {
    /**
     * 
     * @type {string}
     * @memberof UserUpdatingRequest
     */
    login: string;
    /**
     * 
     * @type {string}
     * @memberof UserUpdatingRequest
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof UserUpdatingRequest
     */
    phoneNumber: string;
    /**
     * 
     * @type {string}
     * @memberof UserUpdatingRequest
     */
    fullName: string;
    /**
     * 
     * @type {string}
     * @memberof UserUpdatingRequest
     */
    shortName: string;
    /**
     * 
     * @type {string}
     * @memberof UserUpdatingRequest
     */
    familyName: string;
    /**
     * 
     * @type {string}
     * @memberof UserUpdatingRequest
     */
    givenName: string;
    /**
     * 
     * @type {string}
     * @memberof UserUpdatingRequest
     */
    middleName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserUpdatingRequest
     */
    tenantNames?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserUpdatingRequest
     */
    roleNames?: string | null;
    /**
     * 
     * @type {Array<UserLoginInfoModel>}
     * @memberof UserUpdatingRequest
     */
    logins?: Array<UserLoginInfoModel> | null;
}
/**
 * 
 * @export
 * @interface ValidationProblemDetails
 */
export interface ValidationProblemDetails {
    /**
     * 
     * @type {{ [key: string]: Array<string>; }}
     * @memberof ValidationProblemDetails
     */
    errors?: { [key: string]: Array<string>; } | null;
    /**
     * 
     * @type {string}
     * @memberof ValidationProblemDetails
     */
    type?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ValidationProblemDetails
     */
    title?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ValidationProblemDetails
     */
    status?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ValidationProblemDetails
     */
    detail?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ValidationProblemDetails
     */
    instance?: string | null;
}

/**
 * ApplicationApi - axios parameter creator
 * @export
 */
export const ApplicationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Получить информацию о приложении.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAboutInfo: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/info/application/about`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получить информацию о работоспособности.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHealthInfo: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/info/application/health`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ApplicationApi - functional programming interface
 * @export
 */
export const ApplicationApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Получить информацию о приложении.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAboutInfo(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AboutModel>> {
            const localVarAxiosArgs = await ApplicationApiAxiosParamCreator(configuration).getAboutInfo(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Получить информацию о работоспособности.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getHealthInfo(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HealthModel>> {
            const localVarAxiosArgs = await ApplicationApiAxiosParamCreator(configuration).getHealthInfo(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ApplicationApi - factory interface
 * @export
 */
export const ApplicationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Получить информацию о приложении.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAboutInfo(options?: any): AxiosPromise<AboutModel> {
            return ApplicationApiFp(configuration).getAboutInfo(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получить информацию о работоспособности.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHealthInfo(options?: any): AxiosPromise<HealthModel> {
            return ApplicationApiFp(configuration).getHealthInfo(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ApplicationApi - object-oriented interface
 * @export
 * @class ApplicationApi
 * @extends {BaseAPI}
 */
export class ApplicationApi extends BaseAPI {
    /**
     * 
     * @summary Получить информацию о приложении.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationApi
     */
    public getAboutInfo(options?: any) {
        return ApplicationApiFp(this.configuration).getAboutInfo(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получить информацию о работоспособности.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationApi
     */
    public getHealthInfo(options?: any) {
        return ApplicationApiFp(this.configuration).getHealthInfo(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ConfigurationApi - axios parameter creator
 * @export
 */
export const ConfigurationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Получить конфигурацию клиента.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClientConfig: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/info/configuration/client`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ConfigurationApi - functional programming interface
 * @export
 */
export const ConfigurationApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Получить конфигурацию клиента.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getClientConfig(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ConfigurationApiAxiosParamCreator(configuration).getClientConfig(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ConfigurationApi - factory interface
 * @export
 */
export const ConfigurationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Получить конфигурацию клиента.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClientConfig(options?: any): AxiosPromise<void> {
            return ConfigurationApiFp(configuration).getClientConfig(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ConfigurationApi - object-oriented interface
 * @export
 * @class ConfigurationApi
 * @extends {BaseAPI}
 */
export class ConfigurationApi extends BaseAPI {
    /**
     * 
     * @summary Получить конфигурацию клиента.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigurationApi
     */
    public getClientConfig(options?: any) {
        return ConfigurationApiFp(this.configuration).getClientConfig(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * RoleApi - axios parameter creator
 * @export
 */
export const RoleApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {RoleCreationRequest} [roleCreationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRole: async (roleCreationRequest?: RoleCreationRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/auth/membership/roles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof roleCreationRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(roleCreationRequest !== undefined ? roleCreationRequest : {})
                : (roleCreationRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRole: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteRole.');
            }
            const localVarPath = `/api/v1/auth/membership/roles/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRole: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getRole.');
            }
            const localVarPath = `/api/v1/auth/membership/roles/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {object} [filtration] 
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchRoles: async (filtration?: object, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/auth/membership/roles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            if (filtration !== undefined) {
                localVarQueryParameter['filtration'] = filtration;
            }

            if (pageIndex !== undefined) {
                localVarQueryParameter['PageIndex'] = pageIndex;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (withTotal !== undefined) {
                localVarQueryParameter['WithTotal'] = withTotal;
            }

            if (fromItemInclusive !== undefined) {
                localVarQueryParameter['FromItemInclusive'] = fromItemInclusive;
            }

            if (toItemExclusive !== undefined) {
                localVarQueryParameter['ToItemExclusive'] = toItemExclusive;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {RoleUpdatingRequest} [roleUpdatingRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRole: async (id: string, roleUpdatingRequest?: RoleUpdatingRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateRole.');
            }
            const localVarPath = `/api/v1/auth/membership/roles/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof roleUpdatingRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(roleUpdatingRequest !== undefined ? roleUpdatingRequest : {})
                : (roleUpdatingRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RoleApi - functional programming interface
 * @export
 */
export const RoleApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {RoleCreationRequest} [roleCreationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createRole(roleCreationRequest?: RoleCreationRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoleCreationResponse>> {
            const localVarAxiosArgs = await RoleApiAxiosParamCreator(configuration).createRole(roleCreationRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteRole(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await RoleApiAxiosParamCreator(configuration).deleteRole(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRole(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoleModel>> {
            const localVarAxiosArgs = await RoleApiAxiosParamCreator(configuration).getRole(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {object} [filtration] 
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchRoles(filtration?: object, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoleModelPaginationResponse>> {
            const localVarAxiosArgs = await RoleApiAxiosParamCreator(configuration).searchRoles(filtration, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {RoleUpdatingRequest} [roleUpdatingRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateRole(id: string, roleUpdatingRequest?: RoleUpdatingRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await RoleApiAxiosParamCreator(configuration).updateRole(id, roleUpdatingRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * RoleApi - factory interface
 * @export
 */
export const RoleApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {RoleCreationRequest} [roleCreationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRole(roleCreationRequest?: RoleCreationRequest, options?: any): AxiosPromise<RoleCreationResponse> {
            return RoleApiFp(configuration).createRole(roleCreationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRole(id: string, options?: any): AxiosPromise<void> {
            return RoleApiFp(configuration).deleteRole(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRole(id: string, options?: any): AxiosPromise<RoleModel> {
            return RoleApiFp(configuration).getRole(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {object} [filtration] 
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchRoles(filtration?: object, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: any): AxiosPromise<RoleModelPaginationResponse> {
            return RoleApiFp(configuration).searchRoles(filtration, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {RoleUpdatingRequest} [roleUpdatingRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRole(id: string, roleUpdatingRequest?: RoleUpdatingRequest, options?: any): AxiosPromise<object> {
            return RoleApiFp(configuration).updateRole(id, roleUpdatingRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RoleApi - object-oriented interface
 * @export
 * @class RoleApi
 * @extends {BaseAPI}
 */
export class RoleApi extends BaseAPI {
    /**
     * 
     * @param {RoleCreationRequest} [roleCreationRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoleApi
     */
    public createRole(roleCreationRequest?: RoleCreationRequest, options?: any) {
        return RoleApiFp(this.configuration).createRole(roleCreationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoleApi
     */
    public deleteRole(id: string, options?: any) {
        return RoleApiFp(this.configuration).deleteRole(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoleApi
     */
    public getRole(id: string, options?: any) {
        return RoleApiFp(this.configuration).getRole(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {object} [filtration] 
     * @param {number} [pageIndex] 
     * @param {number} [pageSize] 
     * @param {boolean} [withTotal] 
     * @param {number} [fromItemInclusive] 
     * @param {number} [toItemExclusive] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoleApi
     */
    public searchRoles(filtration?: object, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: any) {
        return RoleApiFp(this.configuration).searchRoles(filtration, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {RoleUpdatingRequest} [roleUpdatingRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoleApi
     */
    public updateRole(id: string, roleUpdatingRequest?: RoleUpdatingRequest, options?: any) {
        return RoleApiFp(this.configuration).updateRole(id, roleUpdatingRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TenantApi - axios parameter creator
 * @export
 */
export const TenantApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {TenantCreationRequest} [tenantCreationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTenant: async (tenantCreationRequest?: TenantCreationRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/auth/general/tenants`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof tenantCreationRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(tenantCreationRequest !== undefined ? tenantCreationRequest : {})
                : (tenantCreationRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTenant: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteTenant.');
            }
            const localVarPath = `/api/v1/auth/general/tenants/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTenant: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getTenant.');
            }
            const localVarPath = `/api/v1/auth/general/tenants/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {object} [filtration] 
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchTenants: async (filtration?: object, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/auth/general/tenants`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            if (filtration !== undefined) {
                localVarQueryParameter['filtration'] = filtration;
            }

            if (pageIndex !== undefined) {
                localVarQueryParameter['PageIndex'] = pageIndex;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (withTotal !== undefined) {
                localVarQueryParameter['WithTotal'] = withTotal;
            }

            if (fromItemInclusive !== undefined) {
                localVarQueryParameter['FromItemInclusive'] = fromItemInclusive;
            }

            if (toItemExclusive !== undefined) {
                localVarQueryParameter['ToItemExclusive'] = toItemExclusive;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {TenantUpdatingRequest} [tenantUpdatingRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTenant: async (id: string, tenantUpdatingRequest?: TenantUpdatingRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateTenant.');
            }
            const localVarPath = `/api/v1/auth/general/tenants/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof tenantUpdatingRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(tenantUpdatingRequest !== undefined ? tenantUpdatingRequest : {})
                : (tenantUpdatingRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TenantApi - functional programming interface
 * @export
 */
export const TenantApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {TenantCreationRequest} [tenantCreationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createTenant(tenantCreationRequest?: TenantCreationRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TenantCreationResponse>> {
            const localVarAxiosArgs = await TenantApiAxiosParamCreator(configuration).createTenant(tenantCreationRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTenant(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await TenantApiAxiosParamCreator(configuration).deleteTenant(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTenant(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TenantModel>> {
            const localVarAxiosArgs = await TenantApiAxiosParamCreator(configuration).getTenant(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {object} [filtration] 
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchTenants(filtration?: object, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TenantModelPaginationResponse>> {
            const localVarAxiosArgs = await TenantApiAxiosParamCreator(configuration).searchTenants(filtration, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {TenantUpdatingRequest} [tenantUpdatingRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTenant(id: string, tenantUpdatingRequest?: TenantUpdatingRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await TenantApiAxiosParamCreator(configuration).updateTenant(id, tenantUpdatingRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * TenantApi - factory interface
 * @export
 */
export const TenantApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {TenantCreationRequest} [tenantCreationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTenant(tenantCreationRequest?: TenantCreationRequest, options?: any): AxiosPromise<TenantCreationResponse> {
            return TenantApiFp(configuration).createTenant(tenantCreationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTenant(id: string, options?: any): AxiosPromise<void> {
            return TenantApiFp(configuration).deleteTenant(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTenant(id: string, options?: any): AxiosPromise<TenantModel> {
            return TenantApiFp(configuration).getTenant(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {object} [filtration] 
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchTenants(filtration?: object, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: any): AxiosPromise<TenantModelPaginationResponse> {
            return TenantApiFp(configuration).searchTenants(filtration, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {TenantUpdatingRequest} [tenantUpdatingRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTenant(id: string, tenantUpdatingRequest?: TenantUpdatingRequest, options?: any): AxiosPromise<object> {
            return TenantApiFp(configuration).updateTenant(id, tenantUpdatingRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TenantApi - object-oriented interface
 * @export
 * @class TenantApi
 * @extends {BaseAPI}
 */
export class TenantApi extends BaseAPI {
    /**
     * 
     * @param {TenantCreationRequest} [tenantCreationRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantApi
     */
    public createTenant(tenantCreationRequest?: TenantCreationRequest, options?: any) {
        return TenantApiFp(this.configuration).createTenant(tenantCreationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantApi
     */
    public deleteTenant(id: string, options?: any) {
        return TenantApiFp(this.configuration).deleteTenant(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantApi
     */
    public getTenant(id: string, options?: any) {
        return TenantApiFp(this.configuration).getTenant(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {object} [filtration] 
     * @param {number} [pageIndex] 
     * @param {number} [pageSize] 
     * @param {boolean} [withTotal] 
     * @param {number} [fromItemInclusive] 
     * @param {number} [toItemExclusive] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantApi
     */
    public searchTenants(filtration?: object, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: any) {
        return TenantApiFp(this.configuration).searchTenants(filtration, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {TenantUpdatingRequest} [tenantUpdatingRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantApi
     */
    public updateTenant(id: string, tenantUpdatingRequest?: TenantUpdatingRequest, options?: any) {
        return TenantApiFp(this.configuration).updateTenant(id, tenantUpdatingRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {PasswordChangingRequest} [passwordChangingRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeUserPassword: async (id: string, passwordChangingRequest?: PasswordChangingRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling changeUserPassword.');
            }
            const localVarPath = `/api/v1/auth/membership/users/{id}/password`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof passwordChangingRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(passwordChangingRequest !== undefined ? passwordChangingRequest : {})
                : (passwordChangingRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UserCreationRequest} [userCreationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUser: async (userCreationRequest?: UserCreationRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/auth/membership/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof userCreationRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(userCreationRequest !== undefined ? userCreationRequest : {})
                : (userCreationRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUser: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteUser.');
            }
            const localVarPath = `/api/v1/auth/membership/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getUser.');
            }
            const localVarPath = `/api/v1/auth/membership/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {object} [filtration] 
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchUsers: async (filtration?: object, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/auth/membership/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            if (filtration !== undefined) {
                localVarQueryParameter['filtration'] = filtration;
            }

            if (pageIndex !== undefined) {
                localVarQueryParameter['PageIndex'] = pageIndex;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (withTotal !== undefined) {
                localVarQueryParameter['WithTotal'] = withTotal;
            }

            if (fromItemInclusive !== undefined) {
                localVarQueryParameter['FromItemInclusive'] = fromItemInclusive;
            }

            if (toItemExclusive !== undefined) {
                localVarQueryParameter['ToItemExclusive'] = toItemExclusive;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UserUpdatingRequest} [userUpdatingRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser: async (id: string, userUpdatingRequest?: UserUpdatingRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateUser.');
            }
            const localVarPath = `/api/v1/auth/membership/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof userUpdatingRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(userUpdatingRequest !== undefined ? userUpdatingRequest : {})
                : (userUpdatingRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {PasswordChangingRequest} [passwordChangingRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changeUserPassword(id: string, passwordChangingRequest?: PasswordChangingRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await UserApiAxiosParamCreator(configuration).changeUserPassword(id, passwordChangingRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {UserCreationRequest} [userCreationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createUser(userCreationRequest?: UserCreationRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserCreationResponse>> {
            const localVarAxiosArgs = await UserApiAxiosParamCreator(configuration).createUser(userCreationRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUser(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await UserApiAxiosParamCreator(configuration).deleteUser(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUser(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserModel>> {
            const localVarAxiosArgs = await UserApiAxiosParamCreator(configuration).getUser(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {object} [filtration] 
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchUsers(filtration?: object, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserModelPaginationResponse>> {
            const localVarAxiosArgs = await UserApiAxiosParamCreator(configuration).searchUsers(filtration, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UserUpdatingRequest} [userUpdatingRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUser(id: string, userUpdatingRequest?: UserUpdatingRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await UserApiAxiosParamCreator(configuration).updateUser(id, userUpdatingRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {PasswordChangingRequest} [passwordChangingRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeUserPassword(id: string, passwordChangingRequest?: PasswordChangingRequest, options?: any): AxiosPromise<void> {
            return UserApiFp(configuration).changeUserPassword(id, passwordChangingRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UserCreationRequest} [userCreationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUser(userCreationRequest?: UserCreationRequest, options?: any): AxiosPromise<UserCreationResponse> {
            return UserApiFp(configuration).createUser(userCreationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUser(id: string, options?: any): AxiosPromise<void> {
            return UserApiFp(configuration).deleteUser(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser(id: string, options?: any): AxiosPromise<UserModel> {
            return UserApiFp(configuration).getUser(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {object} [filtration] 
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchUsers(filtration?: object, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: any): AxiosPromise<UserModelPaginationResponse> {
            return UserApiFp(configuration).searchUsers(filtration, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {UserUpdatingRequest} [userUpdatingRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser(id: string, userUpdatingRequest?: UserUpdatingRequest, options?: any): AxiosPromise<object> {
            return UserApiFp(configuration).updateUser(id, userUpdatingRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
    /**
     * 
     * @param {string} id 
     * @param {PasswordChangingRequest} [passwordChangingRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public changeUserPassword(id: string, passwordChangingRequest?: PasswordChangingRequest, options?: any) {
        return UserApiFp(this.configuration).changeUserPassword(id, passwordChangingRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UserCreationRequest} [userCreationRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public createUser(userCreationRequest?: UserCreationRequest, options?: any) {
        return UserApiFp(this.configuration).createUser(userCreationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public deleteUser(id: string, options?: any) {
        return UserApiFp(this.configuration).deleteUser(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getUser(id: string, options?: any) {
        return UserApiFp(this.configuration).getUser(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {object} [filtration] 
     * @param {number} [pageIndex] 
     * @param {number} [pageSize] 
     * @param {boolean} [withTotal] 
     * @param {number} [fromItemInclusive] 
     * @param {number} [toItemExclusive] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public searchUsers(filtration?: object, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: any) {
        return UserApiFp(this.configuration).searchUsers(filtration, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {UserUpdatingRequest} [userUpdatingRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public updateUser(id: string, userUpdatingRequest?: UserUpdatingRequest, options?: any) {
        return UserApiFp(this.configuration).updateUser(id, userUpdatingRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


