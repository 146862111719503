import React, { useContext, useEffect, useState } from 'react';
import { Button, PageHeader, Row, Table, Tooltip } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import { EditOutlined, PlusCircleOutlined } from '@ant-design/icons';

import { StoreContext } from '../../store';
import { TenantModel } from '../../../api';
import { tenantApi } from '../../apis';
import { AlertTypes } from '../../constants';
import TenantDeletion from '../../components/tenants/TenantDeletion';

const TenantList: React.FC<{}> = () => {
  const { SetAlert } = useContext(StoreContext).actions;
  const [loading, setLoading] = useState(false);
  const [tenants, setTenants] = useState([] as TenantModel[]);
  const [total, setTotal] = useState(0);
  const history = useHistory();

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 25,
  });

  const loadDataAsync = async () => {
    setLoading(true);
    try {
      const response = await tenantApi.searchTenants(undefined, pagination.current - 1, pagination.pageSize);
      setTenants(response.data.items || []);
      setTotal(response.data.totalItemCount || 0);
    } catch (e) {
      SetAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при загрузке списка тенантов',
        description: e.toString(),
      });
    }
    setLoading(false);
  };
  const loadData = () => {
    loadDataAsync();
  };
  useEffect(loadData, [pagination]);

  const onAddBtnClick = () => {
    history.push('/tenants/add');
  };

  const onEditBtnClick = (tenant: TenantModel) => {
    history.push(`/tenants/${tenant.id}/edit`);
  };

  const onTableChange = (tablePagination: any) => setPagination(tablePagination);

  const columns = [
    {
      title: 'ИД',
      dataIndex: 'id',
      ellipsis: false,
      render: (_text: any, tenant: TenantModel) => (
        <Link
          to={{
            pathname: `/tenants/${tenant.id}`,
          }}
        >
          {tenant.id}
        </Link>
      ),
    },
    {
      title: 'Наименование',
      dataIndex: 'name',
      ellipsis: true,
    },
    {
      title: 'Описание',
      dataIndex: 'description',
      ellipsis: true,
    },
    {
      title: 'Действия',
      dataIndex: 'actions',
      render: (_text: any, tenant: TenantModel) => (
        <Row>
          <Tooltip placement="top" title="Изменить тенант">
            <Button
              icon={<EditOutlined />}
              style={{ marginRight: 4 }}
              type="ghost"
              onClick={() => onEditBtnClick(tenant)}
            />
          </Tooltip>
          <TenantDeletion tenant={tenant} onDeleted={loadData} />
        </Row>
      ),
      ellipsis: false,
      width: '120px',
    },
  ];

  return (
    <div>
      <PageHeader
        extra={[
          <Button key="1" icon={<PlusCircleOutlined />} type="primary" onClick={onAddBtnClick}>
            Добавить
          </Button>,
        ]}
        title="Тенанты"
      />
      <Table
        columns={columns}
        dataSource={tenants.map((item, i) => ({ key: i, ...item }))}
        loading={loading}
        pagination={{ ...pagination, total }}
        onChange={onTableChange}
      />
    </div>
  );
};

export default TenantList;
