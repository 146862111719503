import React, { useContext, useEffect, useState } from 'react';
import { Button, PageHeader, Row, Table, Tooltip } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import { EditOutlined, PlusCircleOutlined } from '@ant-design/icons';

import { StoreContext } from '../../store';
import { RoleModel } from '../../../api';
import { roleApi } from '../../apis';
import { AlertTypes } from '../../constants';
import RoleDeletion from '../../components/roles/RoleDeletion';

const RoleList: React.FC<{}> = () => {
  const { SetAlert } = useContext(StoreContext).actions;
  const [loading, setLoading] = useState(false);
  const [roles, setRoles] = useState([] as RoleModel[]);
  const [total, setTotal] = useState(0);
  const history = useHistory();

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 25,
  });

  const loadDataAsync = async () => {
    setLoading(true);
    try {
      const response = await roleApi.searchRoles(undefined, pagination.current - 1, pagination.pageSize);
      setRoles(response.data.items || []);
      setTotal(response.data.totalItemCount || 0);
    } catch (e) {
      SetAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при загрузке списка ролей',
        description: e.toString(),
      });
    }
    setLoading(false);
  };
  const loadData = () => {
    loadDataAsync();
  };
  useEffect(loadData, [pagination]);

  const onAddBtnClick = () => {
    history.push('/roles/add');
  };

  const onEditBtnClick = (role: RoleModel) => {
    history.push(`/roles/${role.id}/edit`);
  };

  const onTableChange = (tablePagination: any) => setPagination(tablePagination);

  const columns = [
    {
      title: 'ИД',
      dataIndex: 'id',
      ellipsis: false,
      render: (_text: any, role: RoleModel) => (
        <Link
          to={{
            pathname: `/roles/${role.id}`,
          }}
        >
          {role.id}
        </Link>
      ),
    },
    {
      title: 'Наименование',
      dataIndex: 'name',
      ellipsis: true,
    },
    {
      title: 'Описание',
      dataIndex: 'description',
      ellipsis: true,
    },
    {
      title: 'Действия',
      dataIndex: 'actions',
      render: (_text: any, role: RoleModel) => (
        <Row>
          <Tooltip placement="top" title="Изменить роль">
            <Button
              icon={<EditOutlined />}
              style={{ marginRight: 4 }}
              type="ghost"
              onClick={() => onEditBtnClick(role)}
            />
          </Tooltip>
          <RoleDeletion role={role} onDeleted={loadData} />
        </Row>
      ),
      ellipsis: false,
      width: '120px',
    },
  ];

  return (
    <div>
      <PageHeader
        extra={[
          <Button key="1" icon={<PlusCircleOutlined />} type="primary" onClick={onAddBtnClick}>
            Добавить
          </Button>,
        ]}
        title="Роли"
      />
      <Table
        columns={columns}
        dataSource={roles.map((item, i) => ({ key: i, ...item }))}
        loading={loading}
        pagination={{ ...pagination, total }}
        onChange={onTableChange}
      />
    </div>
  );
};

export default RoleList;
