import producer from 'immer';

import { ActionType } from '../action/types';
import { IState, ReducerFunc } from '../types';
import { ActionTypes } from '../../constants';

type CreateReducerFunc = (
  handlers: {
    [type in ActionTypes]: ReducerFunc;
  }
) => (state: IState, action: ActionType) => IState;

const createReducer: CreateReducerFunc = (handlers) => {
  // ensure action created is defined in constants/index.js#ActionTypes
  // when we switch to typescript, this is not need anymore.
  Object.keys(handlers).forEach((type) => {
    if (Object.prototype.hasOwnProperty.call(ActionTypes, type) === false) {
      throw new Error(`action created is not defined in constants/index.js#ActionTypes`);
    }
  });

  return (state, action) => {
    const { type, payload } = action;

    // ensure action dispatched is defined in constants/index.js#ActionTypes
    if (Object.prototype.hasOwnProperty.call(ActionTypes, type) === false) {
      throw new Error(`action dispatched is not defined in constants/index.js#ActionTypes`);
    }

    if (Object.prototype.hasOwnProperty.call(handlers, type)) {
      return producer(state, (nextState) => {
        handlers[type](nextState, payload);
      });
    } else {
      return state;
    }
  };
};

export default createReducer;
