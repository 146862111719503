import React, { useContext, useEffect, useState } from 'react';
import { Avatar, Button, Descriptions, Divider, PageHeader, Row, Space, Spin } from 'antd';
import { useParams, useHistory } from 'react-router';
import { ContainerOutlined, EditOutlined, RollbackOutlined } from '@ant-design/icons';

import { StoreContext } from '../../store';
import { TenantModel } from '../../../api';
import { tenantApi } from '../../apis';
import { AlertTypes } from '../../constants';
import ExternalInfoListView from '../../components/tenants/ExternalInfoListView';

const TenantCard: React.FC<{}> = () => {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const { SetAlert } = useContext(StoreContext).actions;
  const [loading, setLoading] = useState(false);
  const [tenant, setTenant] = useState({} as TenantModel);

  const loadDataAsync = async () => {
    setLoading(true);
    try {
      const response = await tenantApi.getTenant(id);
      setTenant(response.data);
    } catch (e) {
      SetAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при загрузке карточки тенанта',
        description: e.toString(),
      });
    }
    setLoading(false);
  };
  const loadData = () => {
    loadDataAsync();
  };
  useEffect(loadData, []);

  const onBackBtnClick = () => {
    history.push('/tenants');
  };

  const onEditBtnClick = () => {
    history.push(`/tenants/${tenant.id}/edit`);
  };

  if (loading)
    return (
      <Row justify="center">
        <Spin />
      </Row>
    );
  return (
    <div>
      <PageHeader
        extra={[
          <Button key="edit" icon={<EditOutlined />} type="primary" onClick={onEditBtnClick}>
            Редактировать
          </Button>,
          <Button key="back" icon={<RollbackOutlined />} onClick={onBackBtnClick}>
            Список
          </Button>,
        ]}
        title={
          <Space>
            <Avatar icon={<ContainerOutlined />} size={64} />
            <Space direction="vertical">
              <span>{tenant.name}</span>
              <span>Тенант</span>
            </Space>
          </Space>
        }
      />
      <Divider />
      <Descriptions bordered column={1}>
        <Descriptions.Item label="ИД">{tenant.id}</Descriptions.Item>
        <Descriptions.Item label="Наименование">{tenant.name}</Descriptions.Item>
        <Descriptions.Item label="Описание">{tenant.description}</Descriptions.Item>
      </Descriptions>
      <Divider />
      <ExternalInfoListView externals={tenant.externals || []} />
    </div>
  );
};

export default TenantCard;
