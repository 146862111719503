import { uuid } from 'uuidv4';

import { ActionTypes } from '../../constants';
import { IAlert, ReducerFunc } from '../types';

import createReducer from './createReducer';

const setAlert: ReducerFunc<IAlert> = (state, alert) => {
  state.alerts = [{ id: uuid(), ...alert }, ...state.alerts];
  return state;
};

const removeAlert: ReducerFunc<string> = (state, id) => {
  state.alerts = state.alerts.filter((item) => item.id !== id);
  return state;
};

export const reducer = createReducer({
  [ActionTypes.SET_ALERT]: setAlert,
  [ActionTypes.REMOVE_ALERT]: removeAlert,
});
