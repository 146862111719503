import React, { useContext, useEffect } from 'react';
import { Alert, Space } from 'antd';

import { StoreContext } from '../store';
import { IAlert } from '../store/types';
import { AlertTypes } from '../constants';

const ALERT_CLOSING_TIMEOUT = 5000;

const Alerts: React.FC<{}> = () => {
  const { state, actions } = useContext(StoreContext);
  const { alerts } = state;
  const { RemoveAlert } = actions;

  const onAlertClose = (alert: IAlert) => () => {
    RemoveAlert(alert.id!);
  };

  const closeAlerts = () => {
    alerts.forEach((alert) => {
      // ошибки автоматом не скрываем
      if (alert.type === AlertTypes.ERROR) {
        return;
      }
      setTimeout(() => {
        RemoveAlert(alert.id!);
      }, ALERT_CLOSING_TIMEOUT);
    });
  };

  useEffect(closeAlerts, [alerts]);

  return (
    <div
      style={{
        position: 'absolute',
        top: 0,
        right: 0,
        maxWidth: '100%',
        minWidth: 300,
        width: 400,
        maxHeight: '100vh',
        zIndex: 1010, // чтоб были поверх модалок
        padding: alerts.length ? 12 : 0,
        overflow: 'auto',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
      }}
    >
      <Space align="end" direction="vertical">
        {alerts.map((alert) => (
          <Alert
            key={alert.id}
            closable
            showIcon
            description={alert.description}
            message={alert.message}
            type={alert.type}
            onClose={onAlertClose(alert)}
          />
        ))}
      </Space>
    </div>
  );
};

export default Alerts;
