// import { once } from "lodash";
import React, { useReducer, useRef } from 'react';

// import { prepareAxios } from "../utils/auth";

import actions, { Actions } from './action';
import bindActions from './action/bindActions';
import { ActionType } from './action/types';
import { reducer } from './reducer';
import { IState } from './types';

const initialState: IState = {
  alerts: [],
};

interface IStoreContextValue {
  state: IState;
  dispatch: React.Dispatch<ActionType>;
  actions: Actions;
}

export const StoreContext = React.createContext<IStoreContextValue>({
  state: initialState,
  dispatch: () => ({}),
  actions: {} as Actions,
});

interface IStoreProviderProps {
  children: React.ReactNode;
}

// const prepareAxiosWithStore = once(prepareAxios);

export const StoreProvider: React.FC<IStoreProviderProps> = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const stateRef = useRef<IState>(initialState);

  stateRef.current = state;
  const getState = () => {
    return stateRef.current;
  };

  const boundActions = bindActions({ dispatch, getState }, actions);
  const value = {
    state: getState(),
    actions: boundActions as Actions,
    dispatch,
  };

  // prepareAxiosWithStore({ dispatch, getState });

  return <StoreContext.Provider value={value}>{props.children}</StoreContext.Provider>;
};
