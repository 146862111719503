import React, { useContext, useState } from 'react';
import { Button, Form, Input, Modal, Row, Tooltip } from 'antd';
import { DeleteOutlined, RollbackOutlined } from '@ant-design/icons';

import { StoreContext } from '../../store';
import { UserModel } from '../../../api';
import { userApi } from '../../apis';
import { AlertTypes } from '../../constants';

export interface IUserDeletionProps {
  user: UserModel;
  onDeleted: () => void;
}

const UserDeletion: React.FC<IUserDeletionProps> = ({ user, onDeleted = () => {} }) => {
  const { SetAlert } = useContext(StoreContext).actions;
  const [modalVisible, setModalVisible] = useState(false);
  const [deletionDisabled, setDeletionDisabled] = useState(true);

  const onOpenModalBtnClick = () => {
    setModalVisible(true);
  };

  const onModalCancel = () => {
    setModalVisible(false);
  };

  const onDelete = async () => {
    if (deletionDisabled) {
      return;
    }

    try {
      await userApi.deleteUser(user.id!);
      SetAlert({
        type: AlertTypes.SUCCESS,
        message: 'Пользователь удален',
        description: user.login || '',
      });
    } catch (e) {
      SetAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при удалении пользователя',
        description: e.toString(),
      });
    }

    setModalVisible(false);
    onDeleted();
  };

  const onLoginEdit = (e: any) => {
    setDeletionDisabled(e.target.value !== user.login);
  };

  return (
    <div>
      <Tooltip placement="top" title="Удалить пользователя">
        <Button danger icon={<DeleteOutlined />} type="ghost" onClick={onOpenModalBtnClick} />
      </Tooltip>
      <Modal
        destroyOnClose
        footer={[
          <Row key="buttons" align="middle" justify="end" style={{ width: '100%' }}>
            <Button icon={<RollbackOutlined />} onClick={onModalCancel}>
              Отмена
            </Button>
            <Button danger disabled={deletionDisabled} icon={<DeleteOutlined />} type="primary" onClick={onDelete}>
              Удалить
            </Button>
          </Row>,
        ]}
        title="Удаление пользователя"
        visible={modalVisible}
        onCancel={onModalCancel}
      >
        <Form onFinish={onDelete}>
          <Form.Item name="name">
            <Input placeholder="Логин" onChange={onLoginEdit} />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default UserDeletion;
