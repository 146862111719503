import React from 'react';
import ReactDOM from 'react-dom';

import { processAuth } from './utils/oidcUtil';
import App from './App';
import './index.css';
import * as serviceWorker from './serviceWorker';
import { StoreProvider } from './store';
import Alerts from './components/Alerts';

(async () => {
  if (!(await processAuth())) {
    return;
  }

  ReactDOM.render(
    <StoreProvider>
      <Alerts />
      <App />
    </StoreProvider>,
    document.getElementById('root')
  );

  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: https://bit.ly/CRA-PWA
  serviceWorker.unregister();
})();
