import React, { useContext, useState } from 'react';
import { Button, Form, Input, PageHeader, Space } from 'antd';
import { useHistory } from 'react-router';
import { RollbackOutlined, SaveOutlined } from '@ant-design/icons';
import { StatusCodes } from 'http-status-codes';

import { UserCreationRequest, UserLoginInfoModel } from '../../../api';
import { StoreContext } from '../../store';
import { userApi } from '../../apis';
import { AlertTypes, IDENTITY_ERROR_PREFIX } from '../../constants';
import LoginInfoListEdit from '../../components/users/LoginInfoListEdit';

const PASSWORD_ERROR_PREFIX = IDENTITY_ERROR_PREFIX + 'Password';

const RoleAdd: React.FC<{}> = () => {
  const [form] = Form.useForm();
  const [saving, setSaving] = useState(false);
  const { SetAlert } = useContext(StoreContext).actions;
  const history = useHistory();
  const [logins, setLogins] = useState([] as UserLoginInfoModel[]);

  const onLoginsChanged = (items: UserLoginInfoModel[]) => {
    setLogins(items);
  };

  const onFinish = async () => {
    const newUser: UserCreationRequest = {
      login: form.getFieldValue('login'),
      password: form.getFieldValue('password'),
      email: form.getFieldValue('email'),
      phoneNumber: form.getFieldValue('phoneNumber'),
      fullName: form.getFieldValue('fullName'),
      shortName: form.getFieldValue('shortName'),
      familyName: form.getFieldValue('familyName'),
      givenName: form.getFieldValue('givenName'),
      middleName: form.getFieldValue('middleName'),
      roleNames: form.getFieldValue('roleNames'),
      tenantNames: form.getFieldValue('tenantNames'),
      logins: logins,
    };

    setSaving(true);
    try {
      const response = await userApi.createUser(newUser);
      SetAlert({
        type: AlertTypes.SUCCESS,
        message: 'Добавлен новый пользователь',
        description: newUser.fullName,
      });
      history.push(`/users/${response.data.id}`);
    } catch (e) {
      if (e.response.status === StatusCodes.UNPROCESSABLE_ENTITY) {
        const problem = e.response.data;
        const errors = Object.keys(problem).filter((k) => k.startsWith(IDENTITY_ERROR_PREFIX));

        if (errors.length) {
          form.setFields([
            {
              name: 'login',
              value: newUser.login,
              errors: errors.filter((k) => !k.startsWith(PASSWORD_ERROR_PREFIX)).map((k) => problem[k]),
            },
            {
              name: 'password',
              value: newUser.password,
              errors: errors.filter((k) => k.startsWith(PASSWORD_ERROR_PREFIX)).map((k) => problem[k]),
            },
          ]);
          setSaving(false);
          return;
        }
      }

      SetAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при сохранении пользователя',
        description: e.toString(),
      });
      setSaving(false);
    }
  };

  const onBackBtnClick = () => {
    history.push('/users');
  };

  return (
    <div>
      <PageHeader title="Новый пользователь" />
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Form.Item label="Логин" name="login" rules={[{ required: true, message: 'Введите логин' }]}>
          <Input placeholder="Логин" />
        </Form.Item>
        <Form.Item label="Пароль" name="password" rules={[{ required: true, message: 'Введите пароль' }]}>
          <Input placeholder="Пароль" type="password" />
        </Form.Item>
        <Form.Item
          label="Email"
          name="email"
          rules={[
            { required: true, message: 'Введите Email' },
            { type: 'email', message: 'Некорректный Email' },
          ]}
        >
          <Input placeholder="Email" />
        </Form.Item>
        <Form.Item label="Телефон" name="phoneNumber" rules={[{ required: true, message: 'Введите номер телефона' }]}>
          <Input placeholder="Телефон" />
        </Form.Item>
        <Form.Item label="Полное ФИО" name="fullName" rules={[{ required: true, message: 'Введите полное ФИО' }]}>
          <Input placeholder="Полное ФИО" />
        </Form.Item>
        <Form.Item label="Краткое ФИО" name="shortName" rules={[{ required: true, message: 'Введите краткое ФИО' }]}>
          <Input placeholder="Краткое ФИО" />
        </Form.Item>
        <Form.Item label="Фамилия" name="familyName" rules={[{ required: true, message: 'Введите фамилию' }]}>
          <Input placeholder="Фамилия" />
        </Form.Item>
        <Form.Item label="Имя" name="givenName" rules={[{ required: true, message: 'Введите имя' }]}>
          <Input placeholder="Имя" />
        </Form.Item>
        <Form.Item label="Отчество" name="middleName">
          <Input placeholder="Отчество" />
        </Form.Item>
        <Form.Item label="Роли" name="roleNames">
          <Input placeholder="Роли" />
        </Form.Item>
        <Form.Item label="Тенанты" name="tenantNames">
          <Input placeholder="Тенанты" />
        </Form.Item>
        <Form.Item>
          <LoginInfoListEdit logins={logins || []} onChanged={onLoginsChanged} />
        </Form.Item>
        <Form.Item>
          <Space>
            <Button disabled={saving} htmlType="submit" icon={<SaveOutlined />} type="primary">
              Сохранить
            </Button>
            <Button icon={<RollbackOutlined />} onClick={onBackBtnClick}>
              Отмена
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </div>
  );
};

export default RoleAdd;
