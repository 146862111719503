import React, { useContext, useState } from 'react';
import { Button, Form, Input, Modal, Row } from 'antd';
import { EditOutlined, SaveOutlined, RollbackOutlined } from '@ant-design/icons';
import { StatusCodes } from 'http-status-codes';

import { StoreContext } from '../../store';
import { UserModel, PasswordChangingRequest } from '../../../api';
import { userApi } from '../../apis';
import { AlertTypes, IDENTITY_ERROR_PREFIX } from '../../constants';

export interface IPasswordChangingProps {
  user: UserModel;
}

const PASSWORD_MISMATCH_KEY = IDENTITY_ERROR_PREFIX + 'PasswordMismatch';

const PasswordChanging: React.FC<IPasswordChangingProps> = ({ user }) => {
  const [form] = Form.useForm();
  const { SetAlert } = useContext(StoreContext).actions;
  const [modalVisible, setModalVisible] = useState(false);

  const openModal = () => {
    setModalVisible(true);
  };

  const closeModal = () => {
    setModalVisible(false);
    form.setFieldsValue({
      currentPassword: null,
      newPassword: null,
    });
  };

  const onSave = () => {
    form.submit();
  };

  const onFinish = async () => {
    const changingRequest: PasswordChangingRequest = {
      currentPassword: form.getFieldValue('currentPassword'),
      newPassword: form.getFieldValue('newPassword'),
    };

    try {
      await userApi.changeUserPassword(user.id!, changingRequest);
      SetAlert({
        type: AlertTypes.SUCCESS,
        message: 'Пароль изменен',
        description: user.login || '',
      });
    } catch (e) {
      if (e.response.status === StatusCodes.UNPROCESSABLE_ENTITY) {
        const problem = e.response.data;
        const errors = Object.keys(problem).filter((k) => k.startsWith(IDENTITY_ERROR_PREFIX));

        if (errors.length) {
          form.setFields([
            {
              name: 'currentPassword',
              value: changingRequest.currentPassword,
              errors: errors.filter((k) => k === PASSWORD_MISMATCH_KEY).map((k) => problem[k]),
            },
            {
              name: 'newPassword',
              value: changingRequest.newPassword,
              errors: errors.filter((k) => k !== PASSWORD_MISMATCH_KEY).map((k) => problem[k]),
            },
          ]);
          return;
        }
      }

      SetAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при изменении пароля',
        description: e.toString(),
      });
    }

    closeModal();
  };

  return (
    <div>
      <Button icon={<EditOutlined />} onClick={openModal}>
        Изменить пароль
      </Button>
      <Modal
        destroyOnClose
        footer={[
          <Row key="buttons" align="middle" justify="end" style={{ width: '100%' }}>
            <Button icon={<RollbackOutlined />} onClick={closeModal}>
              Отмена
            </Button>
            <Button icon={<SaveOutlined />} type="primary" onClick={onSave}>
              Сохранить
            </Button>
          </Row>,
        ]}
        title="Изменение пароля"
        visible={modalVisible}
        onCancel={closeModal}
      >
        <Form form={form} onFinish={onFinish}>
          <Form.Item name="currentPassword" rules={[{ required: true, message: 'Введите текущий пароль' }]}>
            <Input placeholder="Текущий пароль" type="password" />
          </Form.Item>
          <Form.Item name="newPassword" rules={[{ required: true, message: 'Введите новый пароль' }]}>
            <Input placeholder="Новый пароль" type="password" />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default PasswordChanging;
