import React, { useContext, useState, useEffect } from 'react';
import { Button, Form, Input, PageHeader, Space } from 'antd';
import { useParams } from 'react-router';
import { useHistory } from 'react-router';
import { RollbackOutlined, SaveOutlined } from '@ant-design/icons';

import { StoreContext } from '../../store';
import { TenantModel, TenantExternalInfoModel, TenantUpdatingRequest } from '../../../api';
import { tenantApi } from '../../apis';
import { AlertTypes } from '../../constants';
import ExternalInfoListEdit from '../../components/tenants/ExternalInfoListEdit';

const TenantEdit: React.FC<{}> = () => {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const [form] = Form.useForm();
  const { SetAlert } = useContext(StoreContext).actions;
  const [loading, setLoading] = useState(false);
  const [tenant, setTenant] = useState({} as TenantModel);

  const loadDataAsync = async () => {
    setLoading(true);
    try {
      const response = await tenantApi.getTenant(id);
      setTenant(response.data);
    } catch (e) {
      SetAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при загрузке данных тенанта',
        description: e.toString(),
      });
    }
    setLoading(false);
  };
  const loadData = () => {
    loadDataAsync();
  };
  useEffect(loadData, []);

  const loadDataToForm = () => {
    form.setFieldsValue({
      name: tenant.name,
      description: tenant.description,
    });
  };
  useEffect(loadDataToForm, [tenant]);

  const onExternalsChanged = (items: TenantExternalInfoModel[]) => {
    setTenant({
      ...tenant,
      externals: items,
    });
  };

  const onFinish = async () => {
    const newTenant: TenantUpdatingRequest = {
      name: form.getFieldValue('name'),
      description: form.getFieldValue('description'),
      externals: tenant.externals,
    };

    setLoading(true);
    try {
      await tenantApi.updateTenant(id, newTenant);
      SetAlert({
        type: AlertTypes.SUCCESS,
        message: 'Тенант изменен',
        description: newTenant.name,
      });
      history.push(`/tenants/${id}`);
    } catch (e) {
      SetAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при изменении тенанта',
        description: e.toString(),
      });
      setLoading(false);
    }
  };

  const onBackBtnClick = () => {
    history.push('/tenants');
  };

  return (
    <div>
      <PageHeader title={`Редактирование тенанта ${tenant.name}`} />
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Form.Item label="Наименование" name="name" rules={[{ required: true, message: 'Введите наименование' }]}>
          <Input placeholder="Наименование" />
        </Form.Item>
        <Form.Item label="Описание" name="description">
          <Input placeholder="Описание" />
        </Form.Item>
        <Form.Item>
          <ExternalInfoListEdit externals={tenant.externals || []} onChanged={onExternalsChanged} />
        </Form.Item>
        <Form.Item>
          <Space>
            <Button disabled={loading} htmlType="submit" icon={<SaveOutlined />} type="primary">
              Сохранить
            </Button>
            <Button icon={<RollbackOutlined />} onClick={onBackBtnClick}>
              Отмена
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </div>
  );
};

export default TenantEdit;
