import React from 'react';
import { Table } from 'antd';

import { UserLoginInfoModel } from '../../../api';

export interface ILoginlInfoListViewProps {
  logins: UserLoginInfoModel[];
}

const ExternalInfoListView: React.FC<ILoginlInfoListViewProps> = ({ logins }) => {
  const columns = [
    {
      title: 'ИД внешнего поставщика',
      dataIndex: 'loginProvider',
      key: 'loginProvider',
    },
    {
      title: 'ИД пользователя в поставщике',
      dataIndex: 'providerKey',
      key: 'providerKey',
    },
    {
      title: 'Отображаемое имя пользователя в поставщике',
      dataIndex: 'providerDisplayName',
      key: 'providerDisplayName',
    },
  ];

  return <Table columns={columns} dataSource={logins} pagination={false} />;
};

export default ExternalInfoListView;
